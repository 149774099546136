import Merchant from '@/mixins/merchant.js'

export default {
    mixins: [Merchant],
    methods: {
        getFAQURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {

                return 'https://darty-assurance-greendid.crisp.help/fr/'
            }

            return ''
        },
        getFAQDeliveryURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {

                return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-dois-je-emballer-mon-produit-1nf9kkg/'
            }

            return ''
        },
        getFAQContactURL() {

            const subdomain = window.location.hostname.split('.')[0]

            if (subdomain === 'darty-assurance') {
    
                return 'https://darty-assurance-greendid.crisp.help/fr/article/comment-puis-je-vous-contacter-si-jai-une-question-icwl53/'
            }

            return ''
        },
        getContactEmail() {

            const subdomain = window.location.hostname.split('.')[0]

            return 'contact-' + subdomain + '@greendid.com'
        }
    }
}
