export default {
    BASKET_CONDITION: {
        IS_WORKING: {
            SMARTPHONE_APPLE: {
                TITLE: 'Est ce que votre <strong>iPhone</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le réseau etc… fonctionnent normalement.<br/>La batterie de votre smartphone charge correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que votre <strong>smartphone</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement<br/>L\'écran tactile, l\'appareil photo, le réseau etc… fonctionnent normalement.<br/>La batterie de votre smartphone charge correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            LAPTOP: {
                TITLE: 'Est ce que votre <strong>ordinateur portable</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>L\'ordinateur n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'ordinateur fonctionnent normalement.<br/>La batterie de votre ordinateur portable charge correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            OTHER: {
                TITLE: 'Est ce que votre produit fonctionne normalement ?',
                DESCRIPTION: 'Votre produit est bien reconnu par votre ordinateur, il ne génère pas de bug à l\'utilisation.<br/>Sa connectique est en parfait état.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            TABLET_APPLE: {
                TITLE: 'Est ce que votre <strong>iPad</strong> fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le wifi etc… fonctionnent normalement.<br/>La batterie de votre iPad charge correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce que votre <strong>tablette</strong> fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) fonctionnent normalement.<br/>L\'écran tactile, l\'appareil photo, le wifi etc… fonctionnent normalement.<br/>La batterie de votre tablette charge correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            CONSOLE: {
                TITLE: 'Est ce que votre <strong>console</strong> fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons, la connectique et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            GRAPHIC_CARD: {
                TITLE: 'Est ce que votre carte graphique fonctionne normalement ?',
                DESCRIPTION: 'Votre carte graphique est bien reconnue par votre ordinateur, elle ne génère pas de bugs à l\'utilisation.<br>Sa connectique est en parfait état.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            MONITOR: {
                TITLE: 'Est-ce que votre écran d\'ordinateur fonctionne normalement ?',
                DESCRIPTION: 'L\'écran d\'ordinateur s\'allume et s\'éteint normalement.<br/>Les boutons et la connectique fonctionnent Correctement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            WATCH_APPLE: {
                TITLE: 'Est ce que votre Watch fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            WATCH_OTHER: {
                TITLE: 'Est ce que votre Watch fonctionne normalement ?',
                DESCRIPTION: 'Elle s\'allume et s\'éteint normalement.<br/>Les boutons (on/off, volume, home…) et l\'écran tactile fonctionnent normalement.<br/>Pas de message d\'alerte concernant la batterie.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            DESKTOP: {
                TITLE: 'Est ce que votre ordinateur fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et et s\'éteint normalement.<br/>L\'ordinateur n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'ordinateur fonctionnent normalement.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            CAMERA:{
                TITLE: 'Est ce que votre appareil photo fonctionne normalement ?',
                DESCRIPTION: 'Il s\'allume et et s\'éteint normalement.<br/>L\'appareil photo n\'affiche pas de message d\'alerte concernant la batterie.<br/>Toutes les fonctions de l\'appareil photo fonctionnent normalement.'
            },
        },
        CONDITION: {
            SMARTPHONE_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre iPhone',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre smartphone',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            LAPTOP: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre ordinateur portable',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre produit',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            TABLET_APPLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre iPad',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            TABLET_OTHER: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre tablette',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            CONSOLE: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre console',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            MONITOR: {
                TITLE: 'Décrivez <strong>l\'aspect</strong> de votre écran d\'ordinateur',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            WATCH_APPLE: {
                TITLE: 'Décrivez l\'aspect de votre Watch',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            WATCH_OTHER: {
                TITLE: 'Décrivez l\'aspect de votre montre',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            DESKTOP: {
                TITLE: 'Décrivez l\'aspect de votre ordinateur',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Conformément aux Condition de Garantie, nous ne pouvons pas reprendre votre produit à prix garanti dans l’état déclaré.'
            },
            CAMERA: {
                TITLE: 'Décrivez l\'aspect de votre appreil photo',
                DESCRIPTION: 'L\'état de votre produit est vérifié à son arrivée.<br/>Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.'
            },
        },
        IS_PASSWORD_PROTECTED: {
            LAPTOP: {
                TITLE: 'Votre ordinateur portable est-il bloqué par un mot de passe ?',
                DESCRIPTION: 'Il est important de supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur portable.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT204837" target="_blank">un Macbook</a>.',
                ALERT: 'Merci de bien supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur portable.<br/>Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT204837" target="_blank">un Macbook</a>.'
            },
            DESKTOP: {
                TITLE: 'Votre ordinateur est-il bloqué par un mot de passe ?',
                DESCRIPTION: 'Il est important de supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT204837" target="_blank">un Macbook</a>.',
                ALERT: 'Merci de bien supprimer tout mot de passe empêchant l\'utilisation de votre ordinateur.<br/>Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici la procédure pour <a target="_blank" href="https://support.microsoft.com/fr-fr/windows/puis-je-me-connecter-%C3%A0-windows-sans-mot-de-passe-a72b85ae-75ae-4cfa-5c7d-186a61fcce92">un ordinateur sous Windows</a> et la procédure pour <a href="https://support.apple.com/fr-fr/HT204837" target="_blank">un Macbook</a>.'
            }
        },
        IS_RESETED: {
            SMARTPHONE_APPLE: {
                TITLE: 'Votre iPhone est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre iPhone afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPhone.',
                ALERT: 'Merci de bien réinitialiser votre iPhone. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br />Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPhone."'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Votre smartphone est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre smartphone afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre smartphone</a>.',
                ALERT: 'Merci de bien réinitialiser votre smartphone. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre smartphone</a>.'
            },
            TABLET_APPLE: {
                TITLE: 'Votre iPad est-il <strong>réinitialisé</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre iPad afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de le réinitialiser.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPad.',
                ALERT: 'Merci de bien réinitialiser votre iPad. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br />Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT201351">la procédure</a> pour transférer vos données et réinitialiser votre iPad."'
            },
            TABLET_OTHER: {
                TITLE: 'Votre tablette est-elle <strong>réinitialisée</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre tablette afin de ne pas laisser de données personnelles dessus.<br/>Pensez à bien transférer vos données avant de la réinitialiser.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre tablette</a>.',
                ALERT: 'Merci de bien réinitialiser votre tablette. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas.<br/>Voici les procédures pour <a target="_blank" href="https://support.google.com/android/answer/2819582?hl=fr">transférer vos données</a> et <a target="_blank" href="https://support.google.com/android/answer/6088915?hl=fr">réinitialiser votre tablette</a>.'
            },
            WATCH_APPLE: {
                TITLE: 'Votre Watch est-elle <strong>jumelée</strong> avec un iPhone ?',
                DESCRIPTION: 'Il est important de désactiver le jumelage de votre Watch afin de ne pas laisser de données personnelles dessus.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT204568">la procédure pour déjumeler votre Watch</a>.',
                ALERT: 'Merci de bien désactiver le jumelage de votre Watch Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas. Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT204568">la procédure</a>.'
            },
            WATCH_OTHER: {
                TITLE: 'Votre montre est-elle <strong>réinitialisée</strong> ?',
                DESCRIPTION: 'Il est important de réinitialiser votre montre afin de ne pas laisser de données personnelles dessus.<br/>Voici <a target="_blank" href="https://support.google.com/wearos/answer/6056905?hl=fr">les procédures pour réinitialiser votre montre</a>.',
                ALERT: 'Merci de bien réinitialiser votre montre. Nous ne pourrons pas vous proposer d\'offre de reprise si ce n\'est pas le cas. Voici <a target="_blank" href="https://support.google.com/wearos/answer/6056905?hl=fr">les procédures pour réinitialiser votre montre</a>.'
            },
        },
        IS_GEOLOC_ACTIVATED: {
            SMARTPHONE_APPLE: {
                TITLE: 'La fonction <strong> Localiser mon iPhone </strong> est elle <strong> activée </strong> ?',
                DESCRIPTION: 'Attention, aucun produit avec la fonction "Localiser mon iPhone" activée ou bloqué opérateur ne peut être repris.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT211149">la procédure</a> pour désactiver la fonction "Localiser mon iPhone".',
                ALERT: 'Attention, aucun produit avec la fonction "Localiser mon iPhone" activée ou bloqué opérateur ne peut être repris.<br/>Voici <a target="_blank" href="https://support.apple.com/fr-fr/HT211149">la procédure</a> pour désactiver la fonction "Localiser mon iPhone".'
            },
            SMARTPHONE_OTHER: {
                TITLE: 'Est ce que votre smartphone est bloqué opérateur ou par un compte GOOGLE ?',
                DESCRIPTION: 'Attention, aucun produit avec un compte GOOGLE ou un opérateur associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte GOOGLE sur votre appareil.',
                ALERT: 'Attention, aucun produit avec un compte GOOGLE ou un opérateur associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte GOOGLE sur votre appareil.'
            },
            TABLET_OTHER: {
                TITLE: 'Est ce qu\'un compte GOOGLE est associé à votre produit ?',
                DESCRIPTION: 'Attention, aucun produit avec un compte GOOGLE associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte sur votre appareil.',
                ALERT: 'Attention, aucun produit avec un compte GOOGLE associé ne peut être repris.<br/>Voici <a href="https://support.google.com/accounts/answer/81987" target="_blank">la procédure</a> pour désactiver votre compte sur votre appareil.'
            }
        },
        IS_NO_STANDARD_USE: {
            OTHER: {
                TITLE: 'Est ce que le produit a été utilisé pour un usage inhabituel ?',
                DESCRIPTION: '"Overcloking, surchauffe...<br/>L\'état de votre produit est vérifié à son arrivée. Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br/>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Désolé nous ne reprenons pas de produit utilisé de façon anormale.'
            },
            GRAPHIC_CARD: {
                TITLE: 'Est ce que la carte graphique a été utilisée pour un usage inhabituel ?',
                DESCRIPTION: 'overclocking, minage ...<br/>Chaque produit est vérifié à son arrivée. Si les informations fournies sont inexactes, votre produit pourra être refusé ou soumis à une contre-offre de reprise.<br>En cas de retour, <a href="getCguLink()" target="_blank">les frais de livraison vous seront facturés</a>.',
                ALERT: 'Désolé nous ne reprenons pas de produit utilisé de façon anormale.'
            }
        },
        IS_COMPLETE: {
            CONSOLE: {
                TITLE: 'La console est-elle complète ?',
                DESCRIPTION: "Le câble d'alimentation, le câble HDMI, le chargeur et les manettes vendues avec la console sont présents et fonctionnels."
            },
            LAPTOP: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> de votre ordinateur portable ?',
                DESCRIPTION: ''
            },
            DESKTOP: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> de votre ordinateur ?',
                DESCRIPTION: ''
            },
            CAMERA: {
                TITLE: 'Disposez vous du <strong>chargeur d\'origine</strong> et de tous les <strong>accesoires</strong> ?',
                DESCRIPTION: ''
            },
        }
    },
    BASKET_CONDITION_LABEL: {
        SMARTPHONE_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, iPhone déformé"
        },
        SMARTPHONE_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou la coque",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>coque fissurée, smartphone déformé"
        },
        LAPTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        DESKTOP: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure sur le châssis ou l'écran",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures sur le châssis ou l'écran",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes sur le châssis ou l'écran",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, chocs, <br class='d-md-none'>taches sur l'écran ou la châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, erreurs de couleur, <br class='d-md-none'>châssis fissuré, ordinateur déformé"
        },
        OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        CONSOLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        TABLET_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, pas de choc <br class='d-md-none'>ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs",
            5: "Endommagé - <br class='d-md-none'>fissuré, déformé"
        },
        MONITOR: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>écran sans pixels morts, <br class='d-md-none'>sans tâches sur l'écran, <br>pas de chocs ou de rayures sur les bords de l'écran",
            3: "Bon état - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>légères rayures sur les bords de l'écran",
            4: "Correct - <br class='d-md-none'>2 pixels morts maximum, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de chocs sur les bords de l'écran, <br>nombreuses rayures sur les bords de l'écran",
            5: "Endommagé - <br class='d-md-none'>plus de 2 pixels morts, <br class='d-md-none'>tâches et rayures sur l'écran"
        },
        WATCH_APPLE: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        WATCH_OTHER: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
        CAMERA: {
            1: "Comme neuf - <br class='d-md-none'>aucune trace d'usure",
            2: "Très bon état - <br class='d-md-none'>légères traces d'usure, <br class='d-md-none'>micro rayures",
            3: "Bon état - <br class='d-md-none'>légères rayures, <br class='d-md-none'>sans tâches et rayures sur l'écran, <br class='d-md-none'>pas de choc ou de rayures profondes",
            4: "Correct - <br class='d-md-none'>fortes marques d'utilisation, <br class='d-md-none'>chocs, taches sur l'écran ou le châssis",
            5: "Endommagé - <br class='d-md-none'>écran fissuré, <br class='d-md-none'>erreurs de couleur, <br class='d-md-none'>châssis fissuré, <br class='d-md-none'>montre déformée"
        },
    },
    STATUS: {
        STATUS_1: 'A confirmer',
        STATUS_2: 'A expédier',
        STATUS_3: 'Livraison en cours',
        STATUS_4: 'Contrôle du produit<br/>en cours',
        STATUS_5: 'Revente validée',
        STATUS_6: 'Contre offre<br/>à valider',
        STATUS_7: 'Contrôle du Produit<br/>en attente',
        STATUS_8: 'Choisir un type<br/>de bon d\'achat',
        STATUS_9: 'Revente annulée<br/>Produit à récupérer',
        STATUS_10: 'Terminé',
        STATUS_11: 'En cours de validation'
    },
    STATUS_INFO: {
        STATUS_1: "Merci de signer votre bon de cession pour valider l'offre de rachat de votre produit.",
        STATUS_2: "Merci d'expédier votre produit avec l'étiquette de transport fournie.",
        STATUS_3: 'Votre produit est en cours de livraison.',
        STATUS_4: 'Votre produit est en cours de contrôle.',
        STATUS_5: "Votre revente est validée, vous allez recevoir votre bon d'achat d'ici 3 jours.",
        STATUS_6: "Merci d'accepter ou de décliner la contre offre de rachat de votre produit.",
        STATUS_7: 'Vous allez être contacté afin de débloquer le contrôle de votre produit.',
        STATUS_8: "Merci de choisir un type de bon d'achat",
        STATUS_9: 'Votre produit va vous être renvoyé. Merci de régler les frais de livraison.',
        STATUS_10: 'Terminé'
    },
    CATEGORY: {
        LAPTOP: 'ordinateur portable',
        SMARTPHONE_APPLE: 'smartphone',
        SMARTPHONE_OTHER: 'smartphone',
        TABLET_APPLE: 'tablette',
        TABLET_OTHER: 'tablette',
        OTHER: 'produit',
        CONSOLE: 'console',
        GRAPHIC_CARD: 'carte graphique',
        MONITOR: 'écran d\'ordinateur',
        WATCH_APPLE: 'Apple watch',
        WATCH_OTHER: 'Montre connectée',
        DESKTOP: 'ordinateur',
        CAMERA: 'appareil photo',
    },
    SEARCH_FORM: {
        QUESTIONS: {
            category: {
                question: 'Quel produit voulez vous revendre ?',
                label: 'Type de produit'
            },
            displayed_brand: {
                question: 'Quelle est sa marque ?',
                label: 'Marque'
            },
            family: {
                question: 'Quelle est sa famille ?',
                label: 'Famille'
            },
            sub_family: {
                question: 'Quelle est sa sous famille ?',
                label: 'Sous famille'
            },
            model: {
                question: 'Quel est son modèle ?',
                label: 'Modèle'
            },
            option: {
                question: 'Quel option possède t\'il ?',
                label: 'Option'
            },
            capacity: {
                question: 'Quelle est sa capacité de stockage ?',
                label: 'Capacité'
            },
            processor_generation: {
                question: 'Quelle est sa génération de procésseur ?',
                label: 'Génération de processeur'
            },
            gpu: {
                question: 'Quelle est sa carte graphique ?',
                label: 'Carte graphique'
            },
            hard_drive: {
                question: 'Quel est son type de disque dur ?',
                label: 'Type de disque dur'
            },
            ram: {
                question: 'Quelle est sa capacité de la mémoire vive (RAM) ?',
                label: 'Mémoire vive'
            },
            diagonal: {
                question: 'Quelle est sa taille d\'écran ?',
                label: 'Taille de l\'écran'
            },
            processor_family: {
                question: 'Quelle est sa famille de processeur ?',
                label: 'Famille de procésseur'
            },
            display_type: {
                question: 'Quel est son type d\'écran ?',
                label: 'Type d\'écran'
            },
            monitor_diagonal: {
                question: 'Quelle est la taille de votre écran ?',
                label: 'Taille de l\'écran'
            },
            monitor_screen_type: {
                question: 'De quel type est votre écran ?',
                label: 'Type d\'écran'
            },
            monitor_max_resolution: {
                question: 'Quelle est la résolution de votre écran ?',
                label: 'Résolution d\'écran'
            },
            monitor_connectors: {
                question: 'Quelles types de connection à votre écran ?',
                label: 'Type de connections'
            },
            monitor_curve_type: {
                question: 'Votre écran est il incruvé ?',
                label: 'Ecran incurvé'
            },
            monitor_has_speakers: {
                question: 'Votre écran a t il des enceintes intégrées ?',
                label: 'Enceintes intégrées'
            }
        }
    },
    GENERAL: {
        9535101279: {
            PLATFORM_NAME: 'Assurance Darty',
            CONTRACT_PREFIX: 'CTR',
            RESELL_OTHER_TITLE: 'Autre produit',
            RESELL_OTHER_DESCRIPTION: 'Inclus dans le cadre de mon assurance',
            CANT_DO_RESELL: 'Vous ne pouvez pas actuellement revendre un produit dans le cadre de votre contrat.',
            START_DATE: 'Date démarrage'
        },
        3844710917: {
            PLATFORM_NAME: 'Assurant Privilège Pass',
            CONTRACT_PREFIX: '',
            RESELL_NO_PRODUCT_TITLE: 'Revendre un produit - avec bonus',
            RESELL_NO_PRODUCT_DESCRIPTION: 'Profitez d\'un bonus de +20% lors de votre reprise (maximum 200 euros)',
            RESELL_OTHER_TITLE: 'Revendre un produit - sans bonus',
            RESELL_OTHER_DESCRIPTION: 'Revendez un produit en profitant des meilleurs prix',
            CANT_DO_RESELL: 'Vous ne pouvez pas actuellement profiter du bonus de reprise de 20%',
            START_DATE: 'Date de souscription'
        }
    }
}
